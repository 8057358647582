import Breakpoint from '@core/scripts/helper/breakpoint.js';
import MessageBroker from '@bonprix/pattern-library/components/message-broker';
import Messages from '@core/scripts/components/messages';
import JshModule from '@core/scripts/helper/jsh-module';

const {moduleScope} = JshModule('footer/footer-accordion');

const moduleEnabledForBreakpoints = ['small', 'medium'];
let initialized = false;

if (Breakpoint.keyIsIn(moduleEnabledForBreakpoints)) {
    handleAccordionItemBinding();
}

MessageBroker.subscribe(Messages.WINDOW.RESIZED, handleAccordionItemBinding);
MessageBroker.subscribe(Messages.FOOTER.REINIT_ACCORDION, handleAccordionItemBinding);

function handleAccordionItemBinding() {
    if (Breakpoint.keyIsIn(moduleEnabledForBreakpoints)) {
        if (!initialized) {
            const accordionItems = moduleScope.querySelectorAll('.footer-accordion__item-label');

            if (accordionItems) {
                accordionItems.forEach((accordion) => {
                    accordion.addEventListener('click', (e) => toggleAccordion(e));
                });
            }
            const toggleAccordion = (e) => {
                let target = e.currentTarget;
                if (target.getAttribute('aria-expanded') === 'false') {
                    target.setAttribute('aria-expanded', 'true')
                } else {
                    target.setAttribute('aria-expanded', 'false')
                }
            };
            initialized = true;
        }
    } else {
        if (initialized) {
            initialized = false;
        }
    }
}
